import React from "react";

export const About = (props) => {
  return (
    <div id="about">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-12">
              <div className="about-text">
                <h2>About Us</h2>
                <p>Dotted Line is EcoVate's Software as a Service (SaaS) platform. At Dotted Line, we revolutionise the way teams manage procurements and execute projects allowing them to work smarter, not harder through the use of cutting-edge, user friendly technology. Our platform combines advanced AI-driven solutions, integrating project management tools with smart features like automated document drafting, an advisory chat function that leverages EcoVate's knowledge base and extensive experience in public procurement procurement consultancy, and an AI search tool, Framework Finder, that offers a single platform to find the right NHS Approved frameworks and suppliers for your procurement.</p>
                <p>These innovations work together to ensure seamless project delivery, enhance operational efficiency, and provide quick access to essential public sector frameworks and compliance documents. Experience an unmatched toolkit designed for success in a variety of environments, including the NHS.</p>

                <div id="why-dl-container">
                    <h3>Why choose Dotted Line?</h3>
                    <div className="about-bullet-container">
                      <div className="about-bullet"></div>
                      <div className="about-bullet-details">
                          <h5>Reduce Costs</h5>
                          <p>Improve your bottom line by empowering your team to do more in an 8 hour day. Work smarter, not harder.</p>
                      </div>
                    </div>
                    <div className="about-bullet-container">
                      <div className="about-bullet"></div>
                      <div className="about-bullet-details">
                          <h5>Save Time and Drive Efficiency</h5>
                          <p>Dotted Line's AI-driven solutions will minimise manual efforts and drive efficiency.</p>
                      </div>
                    </div>
                    <div className="about-bullet-container">
                      <div className="about-bullet"></div>
                      <div className="about-bullet-details">
                          <h5>Enhance Accuracy and Compliance</h5>
                          <p>Reduce the risk of errors and non-compliance with automated checks and balances.</p>
                      </div>
                    </div>
                    <div className="about-bullet-container">
                      <div className="about-bullet"></div>
                      <div className="about-bullet-details">
                        <h5>Focus on What Matters</h5>
                        <p>Our tools handle the routine tasks, freeing you up to focus on strategic decision-making and business growth.</p>
                      </div>
                    </div>
                    <div className="about-bullet-container">
                      <div className="about-bullet"></div>
                      <div className="about-bullet-details">
                        <h5>Enhance Decision-Making</h5>
                        <p>Dotted Line's AI-driven insights will enable informed decision-making and optimise procurement strategies.</p>
                      </div>
                    </div>
                    <div className="about-bullet-container">
                      <div className="about-bullet"></div>
                        <div className="about-bullet-details">
                          <h5>Experience Personalised Support</h5>
                          <p>Benefit from Dotted Line's dedicated support team, providing personalised assistance and guidance tailored to your specific needs.</p>
                      </div>
                    </div>
                    <div className="about-bullet-container">
                      <div className="about-bullet"></div>
                      <div className="about-bullet-details">
                          <h5>Streamline Collaboration</h5>
                          <p>Facilitate seamless collaboration among your team members and stakeholders with Dotted Line's intuitive platform and communication tools.</p>
                      </div>
                    </div>
                    <div className="about-bullet-container">
                      <div className="about-bullet"></div>
                      <div className="about-bullet-details">
                          <h5>Accelerate Project Delivery</h5>
                          <p>Utilise Dotted Line's streamlined processes and automated workflows to accelerate project delivery and achieve tangible results faster.</p>
                      </div>
                    </div>
                </div>
                
                <div id="ecovate-container">
                  <h3>Partnership with EcoVate Group</h3>
                  <p>Dotted Line has proudly partnered with EcoVate Group, a leader in procurement consultancy, management and commercial expertise. Through our collaboration we aim to enhance procurement processes, delivering exceptional results and value to our clients.</p>
                  <div className="about-bullet-container">
                    <div className="about-bullet"></div>
                    <div className="about-bullet-details">
                      <h5>AI-Driven Procurement Consultancy</h5>
                      <p>EcoVate leverages Dotted Line's AI-driven capabilities to deliver exceptional procurement support. By integrating Dotted Line into its workflow, EcoVate ensures seamless consultancy from start to finish, with streamlined communication, real-time progress tracking, and improved decision-making processes. Dotted Line's AI innovations revolutionise EcoVate's operations, enabling greater efficiency, pattern recognition, and data-driven insights in procurement consultancy and management.</p>
                    </div>
                  </div>
                  <div className="about-bullet-container">
                    <div className="about-bullet"></div>
                    <div className="about-bullet-details">
                      <h5>Elevated User Experience with AI-Driven Expertise</h5>
                      <p>Dotted Line users leverage EcoVate's AI-driven expertise to enhance their procurement consultancy experience. With EcoVate's support, users gain access to specialised guidance on procurement strategies, regulatory compliance, and strategic decision-making. EcoVate's consultancy expertise complements Dotted Line's AI-driven functionality, enriching the overall user experience and ensuring procurement success.</p>
                    </div>
                  </div>
                  <div id="ecovate-logo-container">
                    <a
                      href="https://ecovategroup.com/"
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-custom btn-lg ecovate-btn"
                    >
                      Explore EcoVate Group
                    </a>
                    <a
                      href="https://ecovategroup.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src="../img/logo_ecovate.png" alt="EcoVate Group Logo" style={{ width: '150px'}}/>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

import React from "react";

export const New = (props) => {
  return (
    <div id="framework-finder">
      <div className="container">
        <div className="section-title">
          <span class="new-badge">NEW</span>
          <h2>Framework Finder</h2>
        </div>
        <br />

        <h4>All NHS Approved Frameworks in One Easily Accessible Location</h4>
        <div id="features-container">
          <p>Framework Finder is a quintessential search tool engineered by EcoVate and Dotted Line to streamline the selection of a NHS-approved procurement framework. Designed for precision and specificity, Framework Finder zeroes in on the frameworks that form the backbone of NHS procurement, ensuring buyers can pinpoint the most suitable procurement pathway, framework or supplier with confidence and ease.</p>
          <p>Framework Finder isn't just a search engine; it harnesses the power of cutting-edge semantic search technology to be a strategic guide through the NHS procurement framework landscape, tailored to the unique requirements and context of NHS operations. Framework Finder sets itself apart by concentrating solely on NHS approved frameworks. While others may offer a vast sea of public sector options, Framework Finder delivers a curated experience focused on compliance to NHS standards. Our targeted directory is meticulously aligned with NHS search criteria, providing a more efficient, accurate, and reliable framework search tailored exclusively to NHS procurement needs.</p>
          <p>Say goodbye to the frustration of sifting through countless frameworks. With Framework Finder, navigating the complexity of framework-based procurement is a thing of the past. Our platform is committed to evolving with the NHS procurement ecosystem, continually enhancing our search precision to lead you to the right framework, for the right purpose, at the right time.</p>

          <a
            href="https://dash.dottedl.com/signup"
            target="_blank"
            rel="noreferrer"
            className="btn btn-custom btn-lg signup-btn"
          >
            Sign Up!
          </a>
        </div>
      </div>
    </div>
  );
};

import React from "react";

export const Plans = (props) => {
  return (
    <div id="plans" className="text-center">
      <div className="container-fluid">
        <div className="section-title">
          <h2>Plans</h2>
        </div>
        <br />
        <div id="plans-container">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="pricing-plan">
                  <h4>{d.name}</h4>
                  {d.includes.map(i => (
                    <div className="pricing-plan-item"><p>{i}</p></div>
                  ))}
                </div>
              ))
            : "Loading..."}
        </div>
      </div>
    </div>
  );
};
